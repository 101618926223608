export default function Portfolio() {
    return (
        <div class="my-10 py-20 md:h-full" id="portfolio">
        <div class="max-w-7xl mx-auto px-4">
            <div>
                <h2 class="text-3xl text-gray-600 py-3 uppercase">
                    <b>Recent</b>_Work
                </h2>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-5 text-center">
                <ul>
                    <li>
                        <a href="https://www.yucatan.com.mx" target="_blank" class="font-thin text-lg hover:font-bold transition ease-out duration-500">Diario de Yucatán</a>
                    </li>
                    <li>
                        <a href="https://descubro.mx/" target="_blank" class="font-thin text-lg hover:font-bold transition ease-out duration-500">Descubro</a>
                    </li>
                    <li>
                        <a href="https://cmujer.com.mx/" target="_blank" class="font-thin text-lg hover:font-bold transition ease-out duration-500">CMujer</a>
                    </li>
                </ul>
                <ul>
                    <li>
                        <a href="https://elniplito.dyred.digital/" target="_blank" class="font-thin text-lg hover:font-bold transition ease-out duration-500">El Niplito</a>
                    </li>
                    <li>
                        <a href="https://yucabanas.com/" target="_blank" class="font-thin text-lg hover:font-bold transition ease-out duration-500">Yucabanas</a>
                    </li>
                    <li>
                        <a href="https://heydani.mx/" target="_blank" class="font-thin text-lg hover:font-bold transition ease-out duration-500">Hey Dani</a>
                    </li>
                </ul>
                <ul>

                    <li>
                        <a href="https://soomet.serval.pw/" target="_blank" class="font-thin text-lg hover:font-bold transition ease-out duration-500">Sommet</a>
                    </li>
                    <li>
                        <a href="https://selfix.mx/" target="_blank" class="font-thin text-lg hover:font-bold transition ease-out duration-500">Selfix</a>
                    </li>
                    <li>
                        <a href="http://sushico.mx/" target="_blank" class="font-thin text-lg hover:font-bold transition ease-out duration-500">Sushico</a>
                    </li>
                </ul>
            </div>

        </div>
    </div>        
    )
}