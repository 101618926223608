

export default function Header() {

    return (
        <>
        <header className="max-w-7xl mx-auto px-4">
            <nav className="flex md:flex-row justify-between items-center border-b-2 border-gray-100 py-2 md:py-6">
                <div className="logo uppercase">
                    <h1><a href="/"><strong>Manuel</strong>_Medina</a></h1>
                </div>
                <div>
                    <a href="mailto:me@serval.pw" target="_blank">Get a Contact</a>
                </div>
            </nav>
        </header>
        </>
    )


}