export default function Skills(){
    return (
<div class="my-20 py-20 md:h-full bg-gray-100">
            <div class="max-w-7xl mx-auto px-4">
                <h2 class="text-3xl text-gray-600 py-3 uppercase">
                    <b>Skills</b>_
                </h2>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 text-center">

                    <div>
                        <p class="flex items-center justify-start md:justify-center text-black text-xl font-medium">
                            <svg class="w-10 inline-flex" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>                            Backend / Frontend
                        </p>
                        <p>
                            PHP, Linux, MySql, Nginx, Sql Server, Git, Docker
                        </p>
                        <p>
                            HTML, JavaScript, CSS, Wordpress, Shopify
                        </p>

                    </div>

                    <div>
                        <p class="flex items-center justify-start md:justify-center text-black text-xl font-medium">
                            <svg class="w-10 inline-flex" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"></path></svg>                            Tools
                        </p>
                        <p>
                            Postman, Git, Visual Studio, Visual Studio Code, MSSQL
                        </p>
                        <p>
                            Google (Ad Manager, Analytics, Tag Manager, DataStudio), Hubspot, Active Campaign
                        </p>

                    </div>
                    
                </div>
            </div>
        </div>        
    )
}